<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card
          header="Panel Administrativo - Citas Web"
          class="text-center mt-5"
        >
          <template #header>
            <b-container>
              <b-row>
                <b-col cols="12" sm="5" md="5" lg="3" xl="3" class="text-left">
                  <label for="">Desde:</label>
                  <b-form-datepicker
                    id="datepicker-desde"
                    v-model="reporte.fechaIni"
                    class="mb-2"
                    placeholder="Desde"
                    locale="es"
                  ></b-form-datepicker>
                </b-col>
                <b-col cols="12" sm="5" md="5" lg="3" xl="3" class="text-left">
                  <label for="">Hasta:</label>
                  <b-form-datepicker
                    id="datepicker-hasta"
                    v-model="reporte.fechaFin"
                    class="mb-2"
                    placeholder="Hasta"
                    locale="es"
                  ></b-form-datepicker>
                </b-col>
                <b-col></b-col>
              </b-row>
              <b-row>
                <b-col class="text-left">
                  <label for="">Ver reporte por:</label>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="12" sm="3" md="3" lg="3" xl="3"
                  ><b-button
                    class="btn-menu-principal"
                    block
                    @click="reportexEspecialidades()"
                    >Especialidades</b-button
                  ></b-col
                >
                <b-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <b-button
                    block
                    class="btn-menu-principal"
                    @click="reportexTipoSolicitud()"
                    >Tipo Solicitud</b-button
                  >
                </b-col>
                <b-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <b-button
                    block
                    class="btn-menu-principal"
                    @click="reportexFecha()"
                    >Fecha</b-button
                  >
                </b-col>
                <b-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <b-button
                    block
                    class="btn-menu-principal"
                    @click="reportexFechaUltAño()"
                    >Ultimo año</b-button
                  >
                </b-col>                
              </b-row>
              <b-row class="md-2 mb-2">
                <b-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <b-button
                    block
                    class="btn-menu-principal"
                    @click="reportexSedes()"
                    >Sedes</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="md-2">
                <b-col class="text-left">
                  <b-button
                    class="ml-auto"
                    variant="success"
                    @click="exportDataExcel()">
                    Descargar <b-icon icon="download" aria-hidden="true"></b-icon>
                    </b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    class="ml-auto"
                    variant="danger"
                    @click="salirModuloAdmin()"
                    >Salir</b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </template>
          <b-container>
            <b-row>
              <b-col>
                <div id="chart"></div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import moment from "moment";
  import xlsx from "xlsx";
  import ApexCharts from "apexcharts";
  import axios from "axios";
  export default {
    data() {
      return {
        reporte: {
          fechaIni: moment()
            .subtract(3, "days")
            .format(),
          fechaFin: moment().format(),
        },
        chartData: null,
        chartOptions: null,
        loaded: false,
        chart: null,
        reportes: null
      };
    },
    mounted() {
      //   if (this.userAdmin === null) {
      //     this.$router.replace("/");
      //   }
    },
    computed: {
      ...mapState(["urlProxyApi", "userAdmin", "fechaIngresoAdmin"]),
    },
    methods: {
      async reportexEspecialidades() {
        if (
          moment().format() >
          moment(this.fechaIngresoAdmin)
            .add(1, "h")
            .format()
        ) {
          localStorage.removeItem("userAdmin");
          this.$router.push("/admin");
        }
        if (this.chart) {
          this.chart.destroy();
        }
        let dataToSend = {
          fechaIni: moment(this.reporte.fechaIni).format("YYYY-MM-DD"),
          fechaFin: moment(this.reporte.fechaFin).format("YYYY-MM-DD"),
        };
        let response = await axios.post(
          `${this.urlProxyApi}/reportes/especialidades`,
          dataToSend
        );
        this.reportes='Especialidades'
        this.chartData = response.data.data
        let chartData = response.data.data.map((e) => {
          return e.cantidad;
        });
        let otro = [];
        response.data.data.forEach((e) => {
          let dataValue = [];
          dataValue.push(e.cantidad);
          otro.push({ name: e.especialidad, data: dataValue });
        });

        var options = {
          chart: {
            type: "bar",
            width: "100%",
            height: 350,
          },
          series: otro,
          xaxis: {
            categories: chartData,
            labels: {
              show: false,
            },
          },
          title: {
            text: `Reporte por Especialidades (${dataToSend.fechaIni} - ${dataToSend.fechaFin})`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "Verdana",
              color: "#263238",
            },
          },
        };
        this.chart = new ApexCharts(document.querySelector("#chart"), options);
        this.chart.render();
      },
      async reportexTipoSolicitud() {
        if (
          moment().format() >
          moment(this.fechaIngresoAdmin)
            .add(1, "h")
            .format()
        ) {
          localStorage.removeItem("userAdmin");
          this.$router.replace("/admin");
        }
        if (this.chart) {
          this.chart.destroy();
        }
        let dataToSend = {
          fechaIni: moment(this.reporte.fechaIni).format("YYYY-MM-DD"),
          fechaFin: moment(this.reporte.fechaFin).format("YYYY-MM-DD"),
        };
        let response = await axios.post(
          `${this.urlProxyApi}/reportes/tiposervicio`,
          dataToSend
        );
        this.reportes = "tipo"
        this.chartData = response.data.data
        let chartData = response.data.data.map((e) => {
          return e.cantidad;
        });
        let otro = [];
        response.data.data.forEach((e) => {
          let dataValue = [];
          dataValue.push(e.cantidad);
          otro.push({ name: e.tipo, data: dataValue });
        });

        var options = {
          chart: {
            type: "bar",
            width: "100%",
            height: 350,
          },
          series: otro,
          xaxis: {
            categories: chartData,
            labels: {
              show: false,
            },
          },
          title: {
            text: `Reporte por Tipo de Solicitud (${dataToSend.fechaIni} - ${dataToSend.fechaFin})`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "Verdana",
              color: "#263238",
            },
          },
        };
        this.chart = new ApexCharts(document.querySelector("#chart"), options);
        this.chart.render();
      },
      async reportexFecha() {
        if (
          moment().format() >
          moment(this.fechaIngresoAdmin)
            .add(1, "h")
            .format()
        ) {
          localStorage.removeItem("userAdmin");
          this.$router.replace("/admin");
        }
        if (this.chart) {
          this.chart.destroy();
        }
        let dataToSend = {
          fechaIni: moment(this.reporte.fechaIni).format("YYYY-MM-DD"),
          fechaFin: moment(this.reporte.fechaFin).format("YYYY-MM-DD"),
        };
        let response = await axios.post(
          `${this.urlProxyApi}/reportes/fecha`,
          dataToSend
        );
        this.reportes = "Dias"
        let data=[]
        response.data.data.forEach((e)=>{
          data.push({
            fecha: moment(e.fecha).format("YYYY-MM-DD"),
            cantidad: e.cantidad
          })
        })
        this.chartData = data
        let chartData = response.data.data.map((e) => {
          return e.cantidad;
        });
        let otro = [];
        response.data.data.forEach((e) => {
          let dataValue = [];
          dataValue.push(e.cantidad);
          otro.push({
            name: moment(e.fecha).format("YYYY-MM-DD"),
            data: dataValue,
          });
        });

        var options = {
          chart: {
            type: "bar",
            width: "100%",
            height: 350,
          },
          series: otro,
          xaxis: {
            categories: chartData,
            labels: {
              show: false,
            },
          },
          title: {
            text: `Reporte por Fecha (${dataToSend.fechaIni} - ${dataToSend.fechaFin})`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "Verdana",
              color: "#263238",
            },
          },
        };
        this.chart = new ApexCharts(document.querySelector("#chart"), options);
        this.chart.render();
      },
      async reportexFechaUltAño (){
           if (
          moment().format() >
          moment(this.fechaIngresoAdmin)
            .add(1, "h")
            .format()
        ) {
          localStorage.removeItem("userAdmin");
          this.$router.replace("/admin");
        }
        if (this.chart) {
          this.chart.destroy();
        }
        let dataToSend = {
          fechaIni: moment(this.reporte.fechaIni).format("YYYY-MM-DD"),
          fechaFin: moment(this.reporte.fechaFin).format("YYYY-MM-DD"),
        };
        let response = await axios.post(
          `${this.urlProxyApi}/reportes/fechaMeses`,
          dataToSend
        );
        this.reportes = "Meses"
        let data=[]
        response.data.data.forEach((e)=>{
          data.push({
            fecha: moment(new Date(e.anio, e.mes-1, 1)).locale('es').format('YYYY-MMM'),
            cantidad: e.cantidad
          })
        })
        this.chartData = data
        let chartData = response.data.data.map((e) => {
          return e.cantidad;
        });
        let otro = [];
        response.data.data.forEach((e) => {
          //console.log("fecha ",moment(new Date(e.anio, e.mes-1, 1)).locale('es').format('YYYY-MMM'))
          let dataValue = [];
          dataValue.push(e.cantidad);
          otro.push({
            name: moment(new Date(e.anio, e.mes-1, 1)).locale('es').format('YYYY-MMM'),
            data: dataValue,
          });
        });

        var options = {
          chart: {
            type: "bar",
            width: "100%",
            height: 350,
          },
          series: otro,
          xaxis: {
            categories: chartData,
            labels: {
              show: false,
            },
          },
          title: {
            text: `Reporte Ultimo año`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "Verdana",
              color: "#263238",
            },
          },
        };
        this.chart = new ApexCharts(document.querySelector("#chart"), options);
        this.chart.render();
      },
      async reportexSedes (){
        if (
          moment().format() >
          moment(this.fechaIngresoAdmin)
            .add(1, "h")
            .format()
        ) {
          localStorage.removeItem("userAdmin");
          this.$router.replace("/admin");
        }
        if (this.chart) {
          this.chart.destroy();
        }
        let dataToSend = {
          fechaIni: moment(this.reporte.fechaIni).format("YYYY-MM-DD"),
          fechaFin: moment(this.reporte.fechaFin).format("YYYY-MM-DD"),
        };
        let response = await axios.post(
          `${this.urlProxyApi}/reportes/sedes`,
          dataToSend
        );
        this.reportes = "Sedes"
        let data=[]
        response.data.data.forEach((e)=>{
          data.push({
            sede: e.sede,
            cantidad: e.cantidad
          })
        })
        this.chartData = data
        let chartData = response.data.data.map((e) => {
          return e.cantidad;
        });
        let otro = [];
        response.data.data.forEach((e) => {
          //console.log("fecha ",moment(new Date(e.anio, e.mes-1, 1)).locale('es').format('YYYY-MMM'))
          let dataValue = [];
          dataValue.push(e.cantidad);
          otro.push({
            name: e.sede,
            data: dataValue,
          });
        });

        var options = {
          chart: {
            type: "bar",
            width: "100%",
            height: 350,
          },
          series: otro,
          xaxis: {
            categories: chartData,
            labels: {
              show: false,
            },
          },
          title: {
            text: `Reporte por Sedes`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "Verdana",
              color: "#263238",
            },
          },
        };
        this.chart = new ApexCharts(document.querySelector("#chart"), options);
        this.chart.render();
      },
      exportDataExcel(){
      let data = xlsx.utils.json_to_sheet(this.chartData);
        const workbook = xlsx.utils.book_new();
        const filename = `${this.reportes}`;
        xlsx.utils.book_append_sheet(workbook, data, filename);
        xlsx.writeFile(workbook, `${filename}.xlsx`);
        this.chartData=null
      },
      salirModuloAdmin() {
        localStorage.removeItem("userAdmin");
        localStorage.removeItem("fechaIngresoAdmin");
        this.$router.replace("/");
      },
    },
  };
</script>

<style></style>
